<template>
  <span>
    <template>
      <v-row class="pa-0 ma-0 justify-center">
        <v-footer
          color="transparent"
          class="pt-10"
          style="max-width: 1350px; width: 100%"
        >
          <v-card class="flex" color="transparent">
            <v-row class="mb-10">
              <v-col cols="12" lg="8" class="mx-auto">
                <v-card-text class="px-0 mb-5 text-center text-white">
                  <p class="text-h2" style="font-family: 'Cabin' !important;">JETZT DIE KOSTENLOSE APP HERUNTERLADEN</p>
                  <p class="text-h4">
                    Business Begriffe schnell & leicht verständlich erklärt
                  </p>
                </v-card-text>
                <v-row justify="center">
                  <div>
                    <a
                      href="https://apps.apple.com/de/app/bexikon-business-lexikon/id6446032201"
                      class="text-decoration-none text-uppercase text-white ls-0 mr-2"
                      target="_blank"
                      ><v-img
                        :src="require('../assets/storelogos/appstore.png')"
                        class="my-2"
                        style="cursor: pointer"
                        contain
                        height="75"
                    /></a>
                  </div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.bexikon.app&gl=DE"
                      class="text-decoration-none text-uppercase text-white ls-0 mr-2"
                      target="_blank"
                      ><v-img
                        :src="require('../assets/storelogos/playstore.png')"
                        class="my-2"
                        style="cursor: pointer"
                        contain
                        height="75"
                    /></a>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-footer>
      </v-row>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Impressum",
          link: "https://www.bexikon.de/impressum/",
        },
        {
          linkName: "Datenschutz",
          link: "https://www.bexikon.de/datenschutz/",
        },
        {
          linkName: "AGB",
          link: "https://www.bexikon.de/agb/",
        },
        {
          linkName: "Kontakt",
          link: "https://www.bexikon.de/kontakt/",
        },
      ],
      icons: [
        // {
        //   icon: "fab fa-dribbble",
        // },
        // {
        //   icon: "fab fa-twitter",
        // },
        // {
        //   icon: "fab fa-instagram",
        // },
        // {
        //   icon: "fab fa-pinterest",
        // },
        // {
        //   icon: "fab fa-github",
        // },
      ],
    };
  },
};
</script>
