import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        glossary: [],
        views: [],
        selectedGlossary: null,
        loadingGlossary: false,
    }
}

const state = getDefaultState()

const getters = {
    glossary: state => {
        return state.glossary
    },
    views: state => {
        return state.views
    },
    selectedGlossary: state => {
        return state.selectedGlossary
    },
    loadingGlossary: state => {
        return state.loadingGlossary
    },
}

const mutations = {
    glossary: (state, payload) => {
        state.glossary = payload
    },
    views: (state, payload) => {
        state.views = payload
    },
    selectedGlossary: (state, payload) => {
        state.selectedGlossary = payload
    },
    loadingGlossary: (state, payload) => {
        state.loadingGlossary = payload
    },

}

const actions = {

    getGlossaryByID: ({ commit, dispatch }, id) => {
        commit('loadingGlossary', true)
        axiosAuth.get('/glossary/' + id, {

            })
            .then(
                res => {
                    commit('loadingGlossary', false)
                    if (!res.data.message) {
                        commit('selectedGlossary', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingGlossary', false)

                }
            )
    },

    getGlossary: ({ commit }) => {
        commit('loadingGlossary', true)
        axiosAuth.get('/glossary', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingGlossary', false)
                    if (!res.data.message) {
                        commit('glossary', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingGlossary', false)

                }
            )
    },

    getViews: ({ commit }) => {
        commit('loadingGlossary', true)
        axiosAuth.get('/views', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingGlossary', false)
                    if (!res.data.message) {
                        commit('views', res)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingGlossary', false)

                }
            )
    },
    postGlossaryView: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/views', { glossaryId: payload.id }, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    postGlossary: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/glossary', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getGlossary')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    editGlossary: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.patch('/glossary/' + payload.id, payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getGlossary')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    deleteGlossary: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/glossary/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getGlossary')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}