import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        ratings: [],
        loadingRatings: false,
    }
}

const state = getDefaultState()

const getters = {
    ratings: state => {
        return state.ratings
    },
    loadingRatings: state => {
        return state.loadingRatings
    },
}

const mutations = {
    ratings: (state, payload) => {
        state.ratings = payload
    },
    loadingRatings: (state, payload) => {
        state.loadingRatings = payload
    },

}

const actions = {

    getRatings: ({ commit }) => {
        commit('loadingRatings', true)
        axiosAuth.get('/ratings', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingRatings', false)
                    if (!res.data.message) {
                        commit('ratings', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingRatings', false)

                }
            )
    },

    postRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/ratings', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                    dispatch('getGlossary')
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },


    deleteRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/ratings/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getRatings')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}