import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        categories: [],
        selectedCategory: null,
        selectedCategories: false,
    }
}

const state = getDefaultState()

const getters = {
    categories: state => {
        return state.categories
    },
    selectedCategory: state => {
        return state.selectedCategory
    },
    selectedCategories: state => {
        return state.selectedCategories
    },
}

const mutations = {
    categories: (state, payload) => {
        state.categories = payload
    },
    selectedCategory: (state, payload) => {
        state.selectedCategory = payload
    },
    selectedCategories: (state, payload) => {
        state.selectedCategories = payload
    },

}

const actions = {

    getCategoryByID: ({ commit, dispatch }, id) => {
        commit('selectedCategories', true)
        axiosAuth.get('/categories/' + id, {

            })
            .then(
                res => {
                    commit('selectedCategories', false)
                    if (!res.data.message) {
                        commit('selectedCategory', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('selectedCategories', false)

                }
            )
    },

    getCategory: ({ commit }) => {
        commit('selectedCategories', true)
        axiosAuth.get('/categories', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('selectedCategories', false)
                    if (!res.data.message) {
                        commit('categories', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('selectedCategories', false)

                }
            )
    },

    postCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/categories', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    editCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.patch('/categories/' + payload.id, payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    deleteCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/categories/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}