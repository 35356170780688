<template>
  <v-app>
    <!-- <v-main
      class="auth-pages"
      :style="
        $route.name == 'Begriff'
          ? 'backgroundColor: #fff'
          : 'backgroundColor: #355042'
      "
    > -->
    <v-main
      class="auth-pages app-background-gradient"
    >
      <app-bar-default background="#000" has-bg linkColor="white">
      </app-bar-default>

      <!-- <div
        v-if="$route.name == 'Begriff'"
        class="pb-16 mb-15"
        :style="
          $route.name == 'Begriff'
            ? 'backgroundColor: #355042'
            : 'backgroundColor: #fff'
        "
      > -->
      <div
        v-if="$route.name == 'Begriff'"
        class="pb-16 mb-15 app-background-gradient"
      >
        <v-container v-if="selectedGlossary">
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0">
              <h3 class="text-h3 text-white text-center mb-2 font-cabin" style="font-family: 'Cabin' !important;">
                {{ selectedGlossary.expression }}
              </h3>

              <p
                v-if="selectedGlossary.category"
                class="font-weight-bold text-center font-weight-thin text-uppercase white--text mb-12 text-h4 font-cabin" style="font-family: 'Cabin' !important;"
              >
                {{ selectedGlossary.category.title }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid >
          <v-row justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-card
                class="px-5 pt-1"
                style="background-color: #6d7972"
                rounded="xl"
              >
                <v-row class="ma-0 pa-0 text-white" align="center">
                  <v-col>
                    <v-container>
                      <v-row class="ma-0 pa-0">
                        <div>
                          <v-avatar
                            rounded
                            width="50"
                            height="50"
                            style="
                              background-color: #99a09c;
                              border-radius: 100px !important;
                            "
                          >
                            <v-icon size="25" class="text-white" color="white"
                              >fas fa-star</v-icon
                            >
                          </v-avatar>
                        </div>
                        <div class="ml-3">
                          <p class="text-h5 ma-0 pa-0">{{ getRating() }}</p>
                          <p class="ma-0 pa-0">Sterne</p>
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col>
                    <v-container>
                      <v-row class="ma-0 pa-0">
                        <div>
                          <v-avatar
                            rounded
                            width="50"
                            height="50"
                            style="
                              background-color: #99a09c;
                              border-radius: 100px !important;
                            "
                          >
                            <v-icon size="25" class="text-white" color="white"
                              >fas fa-eye</v-icon
                            >
                          </v-avatar>
                        </div>
                        <div class="ml-3">
                          <p v-if="selectedGlossary" class="text-h5 ma-0 pa-0">
                            {{ selectedGlossary.views.length }}
                          </p>
                          <p v-if="selectedGlossary" class="ma-0 pa-0">
                            Ansicht{{
                              selectedGlossary.views.length > 1 ||
                              selectedGlossary.views.length == 0
                                ? "en"
                                : ""
                            }}
                          </p>
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else class="position-relative pb-16 pt-5"></div>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container
          class="mt-n16 pb-0 mb-10"
          style="min-height: 40vh; max-width: 1400px"
        >
          <router-view></router-view>
        </v-container>
      </fade-transition>
      <v-container
        fluid
        style="background-color: white"
        class="py-6 px-7"
        v-if="$route.name == 'Home'"
      >
        <div style="max-width: 1350px" class="mx-auto">
          <p class="text-h4" style="color: #26392f; font-family: 'Cabin' !important;" >DEFINITION FEHLT?</p>
          <v-btn
            @click="submitSuggestion"
            block
            :ripple="false"
            elevation="0"
            color="#fff"
            class="font-weight-bolder py-5"
            :class="'btn-primary bg-warning'"
            href="javascript:;"
          >
            Vorschlag einreichen
          </v-btn>
        </div>
      </v-container>
      <v-container
        fluid
        style="background-color: white"
        class="py-6 px-7"
        v-if="$route.name == 'Begriff'"
      >
        <div style="max-width: 1350px" class="mx-auto">
          <p class="text-h4" style="color: #26392f">DEFINITION BEWERTEN</p>
          <v-btn
            v-if="checkAlreadyRated()"
            block
            :ripple="false"
            elevation="0"
            color="#fff"
            class="font-weight-bolder py-5"
            :class="'btn-primary bg-warning'"
            href="javascript:;"
          >
            Begriff wurde bereits bewertet
          </v-btn>
          <v-btn
            v-else
            block
            :ripple="false"
            elevation="0"
            color="#fff"
            class="font-weight-bolder py-5"
            :class="'btn-primary bg-warning'"
            href="javascript:;"
            @click="submitRating"
          >
            Jetzt bewerten
          </v-btn>
        </div>
      </v-container>
      <v-container fluid style="background-color: #232323">
        <app-store-cta auth v-if="!$route.meta.hideFooter" />
      </v-container>
      <v-container fluid style="background-color: black" class="pa-0 ma-0">
        <content-footer  auth v-if="!$route.meta.hideFooter" />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppBarDefault from "@/components/AppBarDefault";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import AppStoreCta from "@/components/AppStoreCta.vue";

export default {
  name: "page-layout",
  components: {
    AppBarDefault,
    FadeTransition,
    ContentFooter,
    AppStoreCta,
  },
  computed: {
    selectedGlossary() {
      return this.$store.getters.selectedGlossary;
    },
    categories() {
      return this.$store.getters.categories.data;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    checkAlreadyRated() {
      if (this.userInformations && this.selectedGlossary) {
        if (
          this.selectedGlossary.ratings.find(
            (rating) => rating.userId == this.userInformations.id
          )
        ) {
          return true;
        }
      }
      return false;
    },
    getRating() {
      if (!this.selectedGlossary) return 0.0;
      let rating = 0.0;

      for (const iterator of this.selectedGlossary.ratings) {
        rating += iterator.rating;
      }

      if (rating == 0) return (5.0).toFixed(1);
      return (rating / this.selectedGlossary.ratings.length).toFixed(1);
    },
    submitSuggestion() {
      if (!this.checkLoginState()) return;
      if (!this.userInformations) {
        this.$store.commit("popLogin", true);
      } else {
        this.$store.commit("popSuggestion", true);
      }
    },
    submitRating() {
      if (!this.checkLoginState()) return;
      if (!this.userInformations) {
        this.$store.commit("popLogin", true);
      } else {
        this.$store.commit("popRating", true);
      }
    },
    checkLoginState() {
      console.log("test");
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
  },
};
</script>
<style>
.app-background-gradient {
  background: rgb(13,126,159);
  background: linear-gradient(180deg, rgba(13,126,159,1) 0%, rgba(46,20,187,1) 100%);
}

@font-face {
    font-family: 'Cabin';
    src: url('~@/assets/fonts/Cabin-Bold.ttf');
}

.font-cabin {
    font-family: 'Cabin' !important;
}

</style>