import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        languages: [],
        loadingLanguages: false,
    }
}

const state = getDefaultState()

const getters = {
    languages: state => {
        return state.languages
    },
    loadingLanguages: state => {
        return state.loadingLanguages
    },
}

const mutations = {
    languages: (state, payload) => {
        state.languages = payload
    },
    loadingLanguages: (state, payload) => {
        state.loadingLanguages = payload
    },

}

const actions = {

    getLanguageByID: ({ commit, dispatch }, id) => {
        commit('loadingLanguages', true)
        axiosAuth.get('/languages/' + id, {

            })
            .then(
                res => {
                    commit('loadingLanguages', false)
                    if (!res.data.message) {
                        commit('selectedGlossary', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingLanguages', false)

                }
            )
    },

    getLanguages: ({ commit }) => {
        commit('loadingLanguages', true)
        axiosAuth.get('/language', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingLanguages', false)
                    if (!res.data.message) {
                        commit('languages', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingLanguages', false)

                }
            )
    },

    postLanguage: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/language', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getLanguages')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    editLanguage: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.patch('/language/' + payload.id, payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getLanguages')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    deleteLanguage: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/language/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getLanguages')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}