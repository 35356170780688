import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        suggestions: [],
        loadingSuggestions: false,
    }
}

const state = getDefaultState()

const getters = {
    suggestions: state => {
        return state.suggestions
    },
    loadingSuggestions: state => {
        return state.loadingSuggestions
    },
}

const mutations = {
    suggestions: (state, payload) => {
        state.suggestions = payload
    },
    loadingSuggestions: (state, payload) => {
        state.loadingSuggestions = payload
    },

}

const actions = {


    getSuggestions: ({ commit }) => {
        commit('loadingSuggestions', true)
        axiosAuth.get('/suggestions', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingSuggestions', false)
                    if (!res.data.message) {
                        commit('suggestions', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingSuggestions', false)

                }
            )
    },

    postSuggestion: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/suggestions', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },


    deleteSuggestion: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/suggestions/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getSuggestions')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}