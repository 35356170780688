import { dispatch } from 'd3'
import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        users: [],
        loadingUsers: false,
    }
}

const state = getDefaultState()

const getters = {
    users: state => {
        return state.users
    },
    loadingUsers: state => {
        return state.loadingUsers
    },
}

const mutations = {
    users: (state, payload) => {
        state.users = payload
    },
    loadingUsers: (state, payload) => {
        state.loadingUsers = payload
    },

}

const actions = {

    getUserByID: ({ commit, dispatch }, id) => {
        commit('loadingUsers', true)
        axiosAuth.get('/users/' + id, {

            })
            .then(
                res => {
                    commit('loadingUsers', false)
                    if (!res.data.message) {
                        commit('selectedGlossary', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingUsers', false)

                }
            )
    },

    getUsers: ({ commit }) => {
        commit('loadingUsers', true)
        axiosAuth.get('/users', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('loadingUsers', false)
                    if (!res.data.message) {
                        commit('users', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingUsers', false)

                }
            )
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}