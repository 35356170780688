<template>
  <span style="background-color: black">
    <template>
      <v-container fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0 justify-center">
          <v-footer
            color="transparent"
            style="max-width: 1350px; width: 100%;"
          >
            <v-card class="flex" color="transparent">
              <v-card-text class="px-0">
                <v-container fluid class="pa-0 ma-0">
                  <v-row class="pa-0 ma-0">
                    <a
                      v-for="item in footer"
                      :key="item.linkName"
                      :href="item.link"
                      class="text-decoration-none text-uppercase text-white ls-0 mr-2"
                      style="font-size: 16px"
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </v-row>
                </v-container>
                <!-- <v-row>
                  <v-col cols="12" md="8" class="mb-2">
                    <v-row class="pa-0 ma-0 justify-center">
                      <a
                        v-for="item in footer"
                        :key="item.linkName"
                        :href="item.link"
                        class="text-decoration-none text-uppercase text-white ls-0 mr-2"
                        style="font-size: 16px"
                        target="_blank"
                        >{{ item.linkName }}</a
                      >
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="mx-auto text-center">
                    <p
                      class="copyright text-white  ls-0"
                      style="font-size: 16px"
                    >
                      © {{ new Date().getFullYear() }}
                      - Bexikon Inh. Sven Winkel
                    </p>
                  </v-col>
                </v-row> -->
              </v-card-text>
            </v-card>
          </v-footer>
        </v-row>
      </v-container>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "AGB",
          link: "https://www.bexikon.de/agb/",
        },
        {
          linkName: "Datenschutz",
          link: "https://www.bexikon.de/datenschutz/",
        },
        {
          linkName: "Impressum",
          link: "https://www.bexikon.de/impressum/",
        },
        {
          linkName: "Kontakt",
          link: "https://www.bexikon.de/kontakt/",
        },
      ],
      icons: [
        // {
        //   icon: "fab fa-dribbble",
        // },
        // {
        //   icon: "fab fa-twitter",
        // },
        // {
        //   icon: "fab fa-instagram",
        // },
        // {
        //   icon: "fab fa-pinterest",
        // },
        // {
        //   icon: "fab fa-github",
        // },
      ],
    };
  },
};
</script>
