import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";

// Dashboard pages
const Dashboard = () =>
    import ("../views/Dashboard/Dashboard.vue");
const Automotive = () =>
    import ("../views/Dashboard/Automotive.vue");
const SmartHome = () =>
    import ("../views/Dashboard/SmartHome.vue");

const Glossary = () =>
    import ("../views/Glossary/Glossary.vue");

const Categories = () =>
    import ("../views/Categories/Categories.vue");

const Languages = () =>
    import ("../views/Languages/Languages.vue");
const Users = () =>
    import ("../views/Users/Users.vue");

const Search = () =>
    import ("../views/Search/Search.vue");

const Suggestions = () =>
    import ("../views/Suggestions/Suggestions.vue");

const Crm = () =>
    import ("../views/Dashboard/Crm.vue");
const GettingStarted = () =>
    import ("../views/Dashboard/GettingStarted.vue");

// Pages
const Pricing = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Profile/ProfileOverview.vue"
    );
const Teams = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Projects = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Basic.vue"
    );
const SignUpCover = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Cover.vue"
    );
const SignUpIllustration = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Illustration.vue"
    );

const Home = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Home.vue");

const Kategorie = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Kategorie.vue");
const Begriff = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Begriff.vue");
const Impressum = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Impressum.vue");
const Datenschutz = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Datenschutz.vue");
const Kontakt = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Kontakt.vue");
const AGB = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/AGB.vue");
const Profil = () =>
    import ( /* webpackChunkName: "bexikon" */ "@/views/Bexikon/Profil.vue");

// Applications
const Kanban = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/NewProduct.vue"
    );
const EditProduct = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/EditProduct.vue"
    );
const ProductPage = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/ProductPage.vue"
    );
const OrderList = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderList.vue"
    );
const OrderDetails = () =>
    import (
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderDetails.vue"
    );

const NotFound = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/404.vue");

Vue.use(VueRouter);

// let vrPages = {
//     path: "/",
//     component: DashboardLayoutVr,
//     name: "Vr",
//     children: [{
//             path: "/pages/dashboards/vr/vr-default",
//             name: "VrDefault",
//             component: VrDefault,
//             meta: {
//                 groupName: "Dashboards",
//             },
//         },
//         {
//             path: "/pages/dashboards/vr/vr-info",
//             name: "VrInfo",
//             component: VrInfo,
//             meta: {
//                 groupName: "Dashboards",
//             },
//         },
//     ],
// };

let profilePages = {
    path: "/",
    component: ProfileLayout,
    name: "Profile",
    children: [{
            path: "/pages/pages/profile/overview",
            name: "ProfileOverview",
            component: ProfileOverview,
            meta: {
                groupName: "Pages",
            },
        },
        {
            path: "/pages/pages/profile/teams",
            name: "Teams",
            component: Teams,
            meta: {
                groupName: "Pages",
            },
        },
        {
            path: "/pages/pages/profile/projects",
            name: "Project",
            component: Projects,
            meta: {
                groupName: "Pages",
            },
        },
    ],
};

let userPages = {
    path: "/",
    component: DashboardLayout,
    name: "Users",
    children: [{
            path: "/pages/pages/users/reports",
            name: "Reports",
            component: Reports,
            meta: {
                groupName: "Pages",
            },
        },
        {
            path: "/pages/pages/users/new-user",
            name: "NewUser",
            component: NewUser,
            meta: {
                groupName: "Pages",
            },
        },
    ],
};

let accountPages = {
    path: "/",
    component: DashboardLayout,
    name: "Account",
    children: [{
            path: "/pages/pages/account/settings",
            name: "Settings",
            component: Settings,
            meta: {
                groupName: "Pages",
            },
        },
        {
            path: "/pages/pages/account/billing",
            name: "Billing",
            component: Billing,
            meta: {
                groupName: "Pages",
            },
        },
        {
            path: "/pages/pages/account/invoice",
            name: "Invoice",
            component: Invoice,
            meta: {
                groupName: "Pages",
            },
        },
    ],
};

let projectsPages = {
    path: "/",
    component: DashboardLayout,
    name: "Projects",
    children: [{
        path: "/pages/pages/projects/timeline",
        name: "Timeline",
        component: Timeline,
        meta: {
            groupName: "Pages",
        },
    }, ],
};

let applicationPages = {
    path: "/",
    component: DashboardLayout,
    name: "Application",
    children: [{
            path: "/pages/applications/kanban",
            name: "Kanban",
            component: Kanban,
            meta: {
                groupName: "Applications",
            },
        },
        {
            path: "/pages/applications/wizard",
            name: "Wizard",
            component: Wizard,
            meta: {
                groupName: "Applications",
            },
        },
        {
            path: "/pages/applications/datatables",
            name: "Datatables",
            component: Datatables,
            meta: {
                groupName: "Applications",
            },
        },
        {
            path: "/pages/applications/calendar",
            name: "Calendar",
            component: Calendar,
            meta: {
                groupName: "Applications",
            },
        },
    ],
};

let ecommercePages = {
    path: "/",
    component: ProfileLayout,
    name: "Ecommerce",
    children: [{
        path: "/pages/ecommerce/products/new-product",
        name: "NewProduct",
        component: NewProduct,
        meta: {
            groupName: "Ecommerce",
        },
    }, ],
};

let basePages = {
    path: "/",
    component: PageLayout,
    children: [{
            path: "/",
            name: "Home",
            component: Home,
        }, {
            path: "/suche",
            name: "Home",
            component: Home,
        }, {
            path: "/kategorien",
            name: "Kategorien",
            component: Home,
        }, {
            path: "/kategorien/:id",
            name: "kategorie Details",
            component: Kategorie,
        }, {
            path: "/begriff/:id",
            name: "Begriff",
            component: Begriff,
        }, {
            path: "/kontakt",
            name: "Kontakt",
            component: Kontakt,
        }, {
            path: "/profil",
            name: "Profil",
            component: Profil,
        },
        {
            path: "/impressum",
            name: "Impressum",
            component: Impressum,
        },
        {
            path: "/datenschutz",
            name: "Datenschutz",
            component: Datenschutz,
        }, {
            path: "/agb",
            name: "AGB",
            component: AGB,
        },
    ],
};

let authBasicPages = {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic",
    children: [{
        path: "/pages/authentication/signup/basic",
        name: "SignUpBasic",
        component: SignUpBasic,
    }, ],
};

let authCoverPages = {
    path: "/",
    component: AuthCoverLayout,
    name: "Authentication Cover",
    children: [{
        path: "/pages/authentication/signup/cover",
        name: "SignUpCover",
        component: SignUpCover,
    }, ],
};

let authIllustrationPages = {
    path: "/",
    component: AuthIllustrationLayout,
    name: "Authentication Illustration",
    children: [{
        path: "/pages/authentication/signup/illustration",
        name: "SignUpIllustration",
        component: SignUpIllustration,
    }, ],
};

let notFoundPage = {
    path: "*",
    component: PageLayout,
    name: "Not Found",
    children: [{
        path: "/",
        name: "404",
        component: NotFound,
    }],
};

const routes = [{
        path: "/admin",
        name: "Dashboard",
        // redirect: "/admin/pages/dashboards/default",
        component: DashboardLayout,
        children: [{
                path: "/admin",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/glossar",
                name: "Glossar",
                component: Glossary,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/kategorien",
                name: "Kategorien",
                component: Categories,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/sprachen",
                name: "Sprachen",
                component: Languages,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/nutzer",
                name: "Nutzer",
                component: Users,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/suche",
                name: "Suche",
                component: Search,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/vorschlaege",
                name: "Vorschläge",
                component: Suggestions,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/pages/dashboards/smart-home",
                name: "SmartHome",
                component: SmartHome,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/admin/pages/dashboards/crm",
                name: "Crm",
                component: Crm,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/admin/pages/pages/rtl",
                name: "RTL",
                component: Rtl,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/admin/pages/pages/charts",
                name: "Charts",
                component: Charts,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/admin/pages/pages/alerts",
                name: "Alerts",
                component: Alerts,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/admin/pages/pages/notifications",
                name: "Notifications",
                component: Notifications,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "getting-started",
                name: "Getting Started",
                component: GettingStarted,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/admin/pages/ecommerce/products/edit-product",
                name: "EditProduct",
                component: EditProduct,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/admin/pages/ecommerce/products/product-page",
                name: "ProductPage",
                component: ProductPage,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/admin/pages/ecommerce/orders/list",
                name: "OrderList",
                component: OrderList,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/admin/pages/ecommerce/orders/details",
                name: "OrderDetails",
                component: OrderDetails,
                meta: {
                    groupName: "Ecommerce",
                },
            },
        ],
    },
    basePages,
    notFoundPage,
    profilePages,
    applicationPages,
    ecommercePages,
    userPages,
    accountPages,
    projectsPages,
    // vrPages,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;