<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="position-relative py-0">
        <v-list class="px-3">
          
          <v-list-item
            v-for="category in categories"
            :key="category.title"
            class="mb-0" 
          >
            <v-btn
              :ripple="false"
              text
              class="text-body text-capitalize"
              depressed
              link
              :to="'/kategorien/' + category.link"
            >
              <span class="text-body mb-1">{{ category.title }}</span>
              <!-- <v-spacer></v-spacer> -->
            </v-btn>
          </v-list-item>
        </v-list>
        <hr class="vertical dark" />
      </v-col>
      
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "app-bar-categories",
  computed:{
    categories(){
      return this.$store.getters.categories.data;
    }
  },
  data() {
    return {      
    };
  },
};
</script>
